<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="客户订单编号">
                    <el-input placeholder="请输入订单编号" v-model="queryParams.id" clearable></el-input>
                </el-form-item>
                <el-form-item label="创建时间:" prop="time">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收货姓名:">
                    <el-input placeholder="请输入收货姓名" v-model="queryParams.order" clearable></el-input>
                </el-form-item>
                <el-form-item label="收货手机:">
                    <el-input placeholder="请输入收货手机" v-model="queryParams.order" clearable></el-input>
                </el-form-item>
                <el-form-item label="商品型号:">
                    <el-input placeholder="请输入商品型号" v-model="queryParams.keywords" clearable></el-input>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="changeQuery()">筛选</el-button>
                </el-form-item>
            </el-form>
            <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" @change="changState">
                <el-radio-button label="all">全部</el-radio-button>
                <el-radio-button v-for="(state,index) in repairStateMap" :label="index" :key="index">{{state.admin}}</el-radio-button>
            </el-radio-group>
            <div class="action">
                <el-button type="primary" @click="exportFile">导出</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:20px">
                <el-table-column label="商品信息" fixed="left" width="350">
                    <template slot-scope="scope">
                        <el-row class="table-block-head">
                            <span>售后单号{{scope.row.id}}</span> <span>客户订单号{{scope.row.ordersn}}</span>
                        </el-row>
                        <el-row>
                            <div class="head-box" v-if="scope.row.order_register">
                                <!--  头像  -->
                                <el-image @click="showBigImg(scope.$index)" style="height:60px;width:60px" fit="cover" :class="`column${scope.$index}`" :src="scope.row.order_register.product_img" :preview-src-list="[scope.row.order_register.product_img]">
                                    <div slot="error" class="image-slot">
                                        <el-image fit="cover" :src="defaultImg"></el-image>
                                    </div>
                                </el-image>
                                <el-image @click="showBigImg(scope.$index)" style="height:60px;width:60px" fit="cover" :class="`column${scope.$index}`" :src="scope.row.order_register.bought_ticket" :preview-src-list="[scope.row.order_register.bought_ticket]">
                                    <div slot="error" class="image-slot">
                                        <el-image fit="cover" :src="defaultImg"></el-image>
                                    </div>
                                </el-image>
                                <div class="msg-box">
                                    <span class="name">{{scope.row.order_register.order_goods_name}}</span>
                                </div>
                            </div>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column prop="uid" label="用户id" width="320" align="center">
                    <template slot-scope="scope">
                        <el-row class="table-block-head">
                            <span>创建时间：{{scope.row.ctime}}</span>
                        </el-row>
                        <el-row>
                            <div class="head-box">
                                <!--  头像  -->
                                <div class="user-avatar-box">
                                    <el-image @click="showBigImg(scope.$index)" style="height:40px;width:40px" fit="cover" :class="`column${scope.$index}`" :src="scope.row.user.avatar" :preview-src-list="[scope.row.user.avatar]">
                                        <div slot="error" class="image-slot">
                                            <el-image fit="cover" :src="defaultImg"></el-image>
                                        </div>
                                    </el-image>
                                </div>

                                <div class="user-msg-box">
                                    <span class="name">{{ scope.row.user.nickname }}</span>
                                    <div class="tel">{{ scope.row.user.phone }}</div>
                                </div>
                            </div>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="数量" width="120" align="center">1</el-table-column>
                <el-table-column prop="expresssn" label="物流-收货地址" width="320" align="center">
                    <template slot-scope="scope">
                        <el-row class="table-block-head">
                            <span>{{scope.row.express.name}} : {{scope.row.expresssn}}</span>
                        </el-row>
                        <el-row>
                            <span>{{scope.row.refund_address}}</span>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column prop="reason" label="故障描述" width="150" align="center"></el-table-column>
                <el-table-column prop="state_str.admin" label="订单状态" align="center" width="120">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.state ===0 || scope.row.state===1" @click="updateState(scope.row.id,3)" class="green">通过申请</el-button>
                        <el-button type="text" v-if="scope.row.state ===0" @click="updateState(scope.row.id,1)" class="green">拒绝申请</el-button>

                        <el-button type="text" v-if="scope.row.state =3 && scope.row.state <10 && scope.row.state!==2" @click="updateState(scope.row.id,20)" class="green">确认收件</el-button>

                        <el-button type="text" v-if="scope.row.state >=20 && scope.row.state <30" @click="updateState(scope.row.id,30)" class="green">开始检测</el-button>
                        <el-button type="text" v-if="scope.row.state >=30 && scope.row.state <50" @click="updateState(scope.row.id,50)" class="green">检测完成</el-button>
                        <el-button type="text" v-if="scope.row.state ===50" @click="updateState(scope.row.id,2)" class="green">完成</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import { mapMutations } from 'vuex';

export default {
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            tabPosition: 'all',
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['订单', '售后订单'],
            pageInfo: {
                list: [],
            },
            goodsList: [],
            show: false,
            logisticsVisible: false,
            confirmContent: '',
            confirmVisible: false,
            makeMoneyVisible: false,
            refunt_state: 0,
            choseState: 0,
            refunt_id: 0,
            pay_number: '',
            repairStateMap: [],
        }
    },
    computed: {
        queryParams: {
            set: function(newValue) {
                console.log(111111, newValue)
                this.$store.commit('initQueryParams', newValue)
            },
            get: function(newValue) {
                return this.$store.state.queryParams
            },
        }
    },
    created() {
        this.loadConfig()

        if (this.queryParams.state) {
            if (this.queryParams.state === 1) {
                this.tabPosition = 1
            }
            if (this.queryParams.state === 2) {
                this.tabPosition = 2
            }
            if (this.queryParams.state === 3) {
                this.tabPosition = 3
            }
            if (this.queryParams.state === 4) {
                this.tabPosition = 4
            }
        } else if (this.queryParams.is_complete) {
            if (this.queryParams.is_complete === 2) {
                this.tabPosition = 5
            }
        } else {
            this.tabPosition = 'all'
        }
        this.loadData()



    },
    watch: {
        $route(from) {
            let ArticleEdit = from.fullPath.indexOf('/order-RefundDetail')
            if (ArticleEdit !== -1) {
                this.loadData()
            }
        }
    },
    methods: {
        ...mapMutations({
            'initQueryParams': 'initQueryParams'
        }),
        // 导出
        exportFile() {
            if (this.queryParams.ctime && this.queryParams.etime) {
                request.get('/return/export', { stime: this.queryParams.ctime, etime: this.queryParams.etime }).then(ret => {
                    if (ret.code === 1) {
                        this.$message.success('导出成功');
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                this.$message.error('请选择时间');
            }

        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function() {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function() {
                    show[0].style['display'] = 'none'
                })
            })
        },
        state(id, type) {
            this.refunt_id = id;
            if (type === "refuse") {
                this.refunt_state = 3;
                this.confirmContent = '确认拒绝'
            } else {
                this.refunt_state = 2;
                this.confirmContent = '确认通过'
            }
            this.confirmVisible = true
        },
        loadData() {
            this.loading = true;
            // 获取售后订单列表
            request.get('/order/rights/mini/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadConfig() {
            this.loading = true;
            // 获取售后订单列表
            request.get('/order/rights/get/config', {}).then(ret => {
                if (ret.code === 1) {
                    this.repairStateMap = ret.data.order_right_mini_repair_state
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changState() {
            this.queryParams.page = 1
            this.queryParams.pagesize = 10
            switch (this.tabPosition) {
                case 'all':
                    delete this.queryParams.is_complete
                    delete this.queryParams.state
                    break;
                default:
                    this.queryParams.state = this.tabPosition
                    break;
            }
            this.loadData()
        },

        updateState(rightId, stateEnum) {
            request.post('/order/rights/mini/edit', { id: rightId, state: stateEnum }).then(ret => {
                if (ret.code === 1) {
                    this.$message.success("操作成功")
                    this.loadData()
                } else {

                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        stateData() {
            request.post('/order/rights/mini/edit', { id: this.refunt_id, state: this.refunt_state }).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
                this.confirmVisible = false
            });
        },
        // 查看详情
        goDetail(id) {
            this.$router.replace({ path: '/order-RepairMiniDetail', query: { 'id': id } })
        },
    }
};
</script>

<style lang="scss" scoped>
.table-block-head {
    height: 20px;
    width: 100%;
    margin-bottom: 20px;
}
.table-block-head span {
    font-weight: bold;
    font-size: 14px;
}
.table-block-head span:nth-child(2) {
    margin-left: 20px;
}
.el-table__body .cell {
    padding: 0;
}

.user-avatar-box {
    float: left;
    margin-left: 40px;
}
.user-msg-box {
    margin-left: 40px;
    float: left;
}
</style>
